export class SubmenuInactiveEvent {
    static EVENT_NAME = 'menu-inactive';

    /**
     * @type {SmallscreenNavMenu|LargescreenNavMenu}
     */
    menu;

    /**
     * @param {SmallscreenNavMenu|LargescreenNavMenu} menu
     */
    constructor(menu) {
        this.menu = menu;
    }
}
