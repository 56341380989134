import _isString from 'lodash-es/isString';
import _isArray from 'lodash-es/isArray';

import { hasChild } from './querySelector';
import { Debuggable } from '@gebruederheitz/wp-frontend-utils';

/**
 * Wrap a callback's execution in a generic try/catch block in order to isolate
 * modules from one another.
 *
 * @param {string}    name    A description of what happens in the callback, used for error messages.
 * @param {() => any} module  The callback to be executed within a try/catch block.
 * @param args                Any arguments that need to be passed to `module`.
 */
export function safeModuleInit(name, module, ...args) {
    try {
        module(...args);
    } catch (e) {
        console.error('failed to initialize module ' + name);
        if (Debuggable.prototype.globalJsDebug) {
            console.log(e);
        }
    }
}

export function safeCallback(name, callback, ...args) {
    try {
        callback(...args);
    } catch (e) {
        console.error('failed to initialize module ' + name);
        if (Debuggable.prototype.globalJsDebug) {
            console.log(e);
        }
    }
}

/**
 * Execute the given callback function if the current document contains at
 * least one element matching the given selector(s).
 * The callback's execution is wrapped in a try/catch block. This enables easy
 * isolation of modules from one another.
 *
 * @param {string}          name        A description of what happens in the callback, used for error messages.
 * @param {string|string[]} selectors   A CSS selector string or an array of CSS selector strings to pass to hasChild().
 *                                      When an array is passed, an "OR" combination is used by default, i.e. only one
 *                                      of the selectors must have a match. This can be changed to an "AND" combination
 *                                      (all selectors must match at least one element) with the last parameter.
 * @param {() => R}         callback    The callback to be executed when a matching element is found. Its return value
 *                                      will be returned by whenMatchFound().
 * @param {boolean}         andCombine  Pass true to combine selectors with a logical "AND".
 * @return {R}  The value returned by the callback.
 */
export function whenMatchFound(name, selectors, callback, andCombine = false) {
    let exec = false;
    if (_isString(selectors)) {
        exec = hasChild()(selectors);
    } else if (_isArray(selectors)) {
        const method = andCombine ? 'every' : 'some';
        // "OR" combination: any selector can match is default
        exec = selectors[method]((s) => hasChild()(s));
    }

    if (exec) {
        safeCallback(name, callback);
    }
}
