/**
 * So it appears that pardot uses something derived from
 * https://github.com/rodebert/super-awesome-responsive-iframe-solution
 * which is ultimately forked from
 * https://github.com/Pepo/super-awesome-responsive-iframe-solution
 *
 * I've now taken the "Container-Page" side of the code and modified it slightly:
 * https://github.com/rodebert/super-awesome-responsive-iframe-solution/blob/master/index.html
 *
 *                                                                          ~~AM
 */

const ALLOWED_DOMAINS = [
    'http://fact24.ddev.local',
    'https://fact24.ddev.local',
    'http://fact24.ddev.site',
    'https://fact24.ddev.site',
    'https://f24.ghdev.de',
    'https://fact24.com',
    'https://bze4qw.myraidbox.de',
    'https://go.f24.com',
    'https://go.ecall.com',
    'https://go.ecall-messaging.com',
    'https://tfaforms.com',
];

const receiveMessage = (event) => {
    const message =
        (event && event.data && event.data.split && event.data.split(':')) ||
        [];
    if (!message.length) return;
    const eventName = message[0];
    const eventValue = message[1];
    const eventSource = event.source;

    if (
        ALLOWED_DOMAINS.indexOf(event.origin) !== -1 &&
        eventName === 'resize'
    ) {
        const iframes = document.getElementsByTagName('iframe');

        for (let iframe of iframes) {
            if (
                (iframe.contentWindow || iframe.documentWindow) === eventSource
            ) {
                iframe.style.height = eventValue + 'px';
                break;
            }
        }
    }
};

export const pardotIframeResizer = () => {
    window.addEventListener('message', receiveMessage, false);
};
