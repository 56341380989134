import { SubmenuActiveEvent } from './events/submenu-active-event';

export class SmallscreenNavMenu {
    /**
     * @type {ElementType}
     */
    button;

    /**
     * @type {ElementType}
     */
    menu;

    /**
     * @type {array<int>}
     */
    nestingIndex = [];

    /**
     * @type {EventProxy}
     * @protected
     */
    _eventProxy;

    /**
     * @type {boolean}
     * @protected
     */
    _isExpanded = false;

    /**
     * @param {ElementType} toggleButton
     * @param {EventProxy} eventProxy
     */
    constructor(toggleButton, eventProxy) {
        this._eventProxy = eventProxy;
        this.button = toggleButton;
        this.menu = document.getElementById(
            toggleButton.getAttribute('aria-controls')
        );

        try {
            this.nestingIndex = JSON.parse(toggleButton.dataset.index) ?? [];
        } catch {
            this.nestingIndex = [];
        }

        this._bindEventHandlers();
        this._listen();
    }

    destroy() {
        this.button.removeEventListener('click', this._onClick);
    }

    hide() {
        if (this._isExpanded) {
            this._toggleActiveState();
        }
    }

    _bindEventHandlers() {
        this._onClick = this._onClick.bind(this);
    }

    _listen() {
        this.button.addEventListener('click', this._onClick);
        this._eventProxy.on(
            SubmenuActiveEvent.EVENT_NAME,
            this._onSubmenuActive
        );
    }

    _onSubmenuActive = (e) => {
        if (this._isExpanded && e.menu !== this) {
            let incomingIndex = [...e.menu.nestingIndex];
            let ownIndex = [...this.nestingIndex];
            while (incomingIndex.length) {
                if (!ownIndex.length) {
                    // this is a child of ours
                    break;
                }

                const incomingLevel = incomingIndex.shift();
                const ownLevel = ownIndex.shift();

                if (incomingLevel !== ownLevel) {
                    this._toggleActiveState();
                }
            }
        }
    };

    _onClick() {
        this._toggleActiveState();

        if (this._isExpanded) {
            this._eventProxy.triggerEvent(SubmenuActiveEvent, this);
        }
    }

    _toggleActiveState() {
        this._isExpanded = !this._isExpanded;
        this.button.setAttribute('aria-expanded', this._isExpanded);
        this.menu.setAttribute('aria-hidden', !this._isExpanded);
    }
}
