import mitt from 'mitt';

class EventProxy {
    _emitter;

    constructor() {
        this._emitter = mitt();
    }

    /**
     * @template {*} R
     * @template {Class} T
     * @param {T<R>} eventClass
     * @param {...R} details
     */
    triggerEvent(eventClass, ...details) {
        this._emitter.emit(eventClass.EVENT_NAME, new eventClass(...details));
    }

    get on() {
        return this._emitter.on;
    }

    get emit() {
        return this._emitter.emit;
    }

    get off() {
        return this._emitter.off;
    }
}

export const eventProxy = new EventProxy();
