export const selectors = {
    collapsible: '.ghwp-collapsible',
    efficiencyCalculator: '.ghwp-ecalc',
    embed: '[data-ghct-src], [data-ghct-type]',
    inputInteraction: ['.ghwp-ecalc__field'],
    lightbox: 'a[href$=".gif"], a[href$=".jpg"], a[href$=".png"]',
    videoLightbox: '.ghwp-video-link:not([data-ghct-type])',
    personioIframe: 'iframe[src*="personio.de"]',
    postFilter: '#ghwp-category-select',
    prism: 'code, pre',
    progressbar: '.ghwp-progressbar',
    readMore: '.ghwp-read-more',
    slideshow: '.ghwp-slideshow',
    statsCounter: '.ghwp-counter:not(.ghwp-counter--ticker)',
    styledSelect: '.pd-select > select',
    table: '.wp-block-table',
    tabs: '.ghwp-tabs',
    ticker: '.ghwp-counter--ticker',
};
