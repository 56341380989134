import { SubmenuActiveEvent } from './events/submenu-active-event';
import { SubmenuInactiveEvent } from './events/submenu-inactive-event';
import { SmallscreenNavMenu } from './smallscreen-nav-menu';

export class LargescreenNavMenu extends SmallscreenNavMenu {
    /**
     * @type {?ElementType}
     * @private
     */
    _eventParent = null;

    destroy() {
        super.destroy();

        this.button.removeEventListener('click', this._onClick);
        this.button.removeEventListener('mouseenter', this._onMouseEnter);
        this._eventParent &&
            this._eventParent.removeEventListener(
                'mouseleave',
                this._onMouseLeave
            );
    }

    _bindEventHandlers() {
        this._onClick = this._onClick.bind(this);
        this._onOtherMenuActive = this._onOtherMenuActive.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    _listen() {
        super._listen();

        this.button.addEventListener('mouseenter', this._onMouseEnter);

        const navItem = this.button.parentElement;
        if (navItem.matches('.ghwp-main-nav__item')) {
            this._eventParent = navItem;
            navItem.addEventListener('mouseleave', this._onMouseLeave);
        }

        this._eventProxy.on(
            SubmenuActiveEvent.EVENT_NAME,
            this._onOtherMenuActive
        );
    }

    _onClick() {
        this._toggleActiveState();

        if (this._isExpanded) {
            this._eventProxy.triggerEvent(SubmenuActiveEvent, this);
        }
    }

    _onMouseEnter() {
        if (!this._isExpanded) {
            this._toggleActiveState();
            this._eventProxy.triggerEvent(SubmenuActiveEvent, this);
        }
    }

    _onMouseLeave() {
        if (this._isExpanded) {
            this._toggleActiveState();
            this._eventProxy.triggerEvent(SubmenuInactiveEvent, this);
        }
    }

    /**
     * @param {SubmenuActiveEvent} e
     */
    _onOtherMenuActive(e) {
        if (this._isExpanded && e.menu !== this) {
            this._toggleActiveState();
        }
    }

    _toggleActiveState() {
        this._isExpanded = !this._isExpanded;
        this.button.setAttribute('aria-expanded', this._isExpanded);
        const method = this._isExpanded ? 'add' : 'remove';
        this.button.parentElement.classList[method]('open');
        this.menu.setAttribute('aria-hidden', !this._isExpanded);
    }
}
