import { createDomElement } from '@gebruederheitz/wp-frontend-utils';

export class LargescreenSubnavButton {
    /**
     * @type {ElementType}
     * @private
     */
    _element;

    /**
     * @type {ElementType}
     * @private
     */
    _originalElement;

    constructor(buttonElement, classNames) {
        this._originalElement = buttonElement;
        this.transform(buttonElement, classNames);
    }

    transform(buttonElement, classNames) {
        const txt = buttonElement.querySelector('span').textContent;

        const replacement = createDomElement({
            type: 'SPAN',
            classNames: [classNames.subnavButtonText],
            innerText: txt,
        });

        buttonElement.parentNode.replaceChild(replacement, buttonElement);

        this._element = replacement;
    }

    restore() {
        this._element.parentNode.replaceChild(
            this._originalElement,
            this._element
        );
    }
}
