/**
 * Open links to (larger) images in modal overlay/lightbox
 */

import { $$ } from './utils/querySelector';

import { LightboxFactory } from '@gebruederheitz/wp-block-video-overlay/dist/frontend';
import { createDomElement } from '@gebruederheitz/wp-frontend-utils';
import { closeIcon } from './utils/icons';

const plyrOptions = {
    js: '/wp-content/themes/ghwp/vendor/plyr/plyr.js',
    css: '/wp-content/themes/ghwp/vendor/plyr/plyr.css',
    config: {
        iconUrl: '/wp-content/themes/ghwp/vendor/plyr/plyr.svg',
    },
};

export const factory = new LightboxFactory(plyrOptions);

const Skins = {
    FORM: 'form',
    SMALL: 'small',
    MEDIUM: 'medium',
    CLEAN: 'clean',
};

function getSkin(modal) {
    const isFormStyle = modal.classList.contains('is-style-form');
    const isSmallStyle = modal.classList.contains('is-style-compact-small');
    const isMediumStyle = modal.classList.contains('is-style-compact-medium');

    if (isFormStyle) {
        return Skins.FORM;
    } else if (isSmallStyle) {
        return Skins.SMALL;
    } else if (isMediumStyle) {
        return Skins.MEDIUM;
    }

    return Skins.CLEAN;
}

function addCustomCloseButton(modal, lightbox) {
    const closeButton = createDomElement({
        classNames: ['ghwp-modal-close'],
        parent: modal,
        innerHtml: closeIcon,
    });

    closeButton.addEventListener('click', () => {
        lightbox.close();
    });
}

export const modal = (
    modalSelector = '.ghwp-modal',
    buttonSelector = 'a[href^="#"]'
) => {
    const modals = $$()(modalSelector);
    if (modals.length > 0) {
        for (let modal of modals) {
            const skin = getSkin(modal);
            const isCompactSkin = [Skins.MEDIUM, Skins.SMALL].includes(skin);

            const lb = factory.custom({
                selector: null,
                onOpen: () => {
                    modal.classList.remove('ghwp-hide');
                },
                skin,
                closeButton: !isCompactSkin,
            });

            if (isCompactSkin) {
                addCustomCloseButton(modal, lb);
            }

            lb.setElements([
                {
                    content: modal,
                    type: 'inline',
                },
            ]);

            const buttons = $$()(buttonSelector);
            for (let button of buttons) {
                const hash = button.href.match(/#([^?&\s]*)/);
                if (hash.length >= 2 && hash[1] === modal.id) {
                    button.addEventListener('click', (event) => {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                        lb.open();
                    });
                }
            }
        }
    }
};
