/**
 * Sticky event card
 */

function eventCardPositionCalculator(windowHeight, targetHeight) {
    return Math.ceil(windowHeight / 2 - targetHeight / 2);
}

export const eventCard = (
    card = 'ghwp-card-event',
    btn = 'ghwp-card-event-close',
    breakpoint = '768px'
) => {
    const target = document.getElementById(card);
    const toggle = document.getElementById(btn);

    if (!target || !window.matchMedia(`(min-width: ${breakpoint})`).matches)
        return;

    const targetHeight = target.offsetHeight;
    // Viewport height for positioning the event banner
    const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    // Position event banner vertically centered
    const targetTop = eventCardPositionCalculator(windowHeight, targetHeight);
    target.style.top = `${targetTop}px`;

    // Show event banner
    target.setAttribute('aria-hidden', 'false');

    // Check how far we may have scrolled
    let scrollPosition = window.scrollY;

    window.addEventListener('scroll', function () {
        scrollPosition = window.scrollY;
        // Hide event banner at 2x viewport height
        if (scrollPosition > windowHeight * 2) {
            target.setAttribute('aria-hidden', 'true');
        }
    });

    // Close button event handler
    toggle.addEventListener('click', function () {
        target.setAttribute('aria-hidden', 'true');
    });
};
