import { LanguageMenuToggleEvent } from './events/language-menu-toggle-event';

export class LanguageMenu {
    /**
     * @type {ElementType}
     * @private
     */
    _button;

    /**
     * @type {EventProxy}
     * @private
     */
    _eventProxy;

    /**
     * @type {ElementType}
     * @private
     */
    _menu;

    _isMenuOpen = false;

    /**
     * @param {ElementType} button
     * @param {ElementType} menu
     * @param {EventProxy}  eventProxy
     */
    constructor(button, menu, eventProxy) {
        if (!button || !menu) return;

        this._button = button;
        this._menu = menu;
        this._eventProxy = eventProxy;

        this._listen();
    }

    hide() {
        if (this._isMenuOpen) {
            this._toggleMenu();
        }
    }

    _listen() {
        this._button.addEventListener('click', this._onButtonClick);
    }

    _onButtonClick = () => {
        this._toggleMenu();
    };

    _toggleMenu() {
        this._isMenuOpen = !this._isMenuOpen;

        this._eventProxy.triggerEvent(
            LanguageMenuToggleEvent,
            this._isMenuOpen,
            this
        );

        this._button.setAttribute('aria-expanded', this._isMenuOpen);
        this._menu.setAttribute('aria-hidden', !this._isMenuOpen);
    }
}
