import { $$, Debuggable } from '@gebruederheitz/wp-frontend-utils';

import { Selectable } from './util/selectable';
import { LargescreenNavMenu } from './largescreen-nav-menu';
import { SubmenuActiveEvent } from './events/submenu-active-event';
import { SubmenuInactiveEvent } from './events/submenu-inactive-event';
import { LargescreenSubnavButton } from './largescreen-subnav-button';

export class LargescreenNav extends Debuggable {
    /**
     * @type {Object<string, string>}
     * @private
     */
    _classNames;

    /**
     * @type {LargescreenNavMenu}
     * @private
     */
    _currentlyOpenMenu;

    /**
     * @type {EventProxy}
     * @private
     */
    _eventProxy;

    /**
     * @type {ElementType}
     * @private
     */
    _navbar;

    /**
     * @type {SelectorCategory}
     * @private
     */
    _selectors;

    /**
     * @type {array<LargescreenNavMenu>}
     * @private
     */
    _submenus;

    /**
     * @type {array<LargescreenSubnavButton>}
     * @private
     */
    _subnavButtons;

    /**
     * @type {array<ElementType>}
     * @private
     */
    _subnavMenus;

    /**
     * @param {EventProxy} eventProxy
     * @param {SelectorList} selectors
     * @param {Object<string, string>} classNames
     */
    constructor(eventProxy, selectors, classNames) {
        super('LargescreenNav');

        this._eventProxy = eventProxy;
        this._selectors = selectors.largescreen;
        this._classNames = classNames;

        this._initCurrentNavItem();

        this._navbar = Selectable.query(this._selectors.navbar);
        const buttons = Selectable.queryAll(this._selectors.navButtons);

        this._transformSubnavs();
        this._initCurrentNavItem();

        this._initSubnavs(buttons);
        this._listen();
    }

    destroy() {
        this.debug.log('Destroying instance');

        this._eventProxy.off(
            SubmenuActiveEvent.EVENT_NAME,
            this._onSubmenuActive
        );
        this._eventProxy.off(
            SubmenuInactiveEvent.EVENT_NAME,
            this._onSubmenuInactive
        );
        document.removeEventListener('click', this._onClickOutside);

        this._submenus.forEach((subnav) => subnav.destroy());
        this._restoreSubnavs();
    }

    _initCurrentNavItem() {
        const path = window.location.href;
        $$()('#ghwp-main-nav a[href="' + path + '"]').forEach((e) => {
            e.parentElement.classList.add(this._classNames.mainNavActive);
            const closestNavItem = e.closest('.ghwp-main-nav__item');
            closestNavItem &&
                closestNavItem.classList.add(this._classNames.mainNavActive);
        });
    }

    _initSubnavs(buttons) {
        this._submenus = buttons.map(
            (button) => new LargescreenNavMenu(button, this._eventProxy)
        );
    }

    _listen() {
        this._eventProxy.on(
            SubmenuActiveEvent.EVENT_NAME,
            this._onSubmenuActive
        );
        this._eventProxy.on(
            SubmenuInactiveEvent.EVENT_NAME,
            this._onSubmenuInactive
        );
        document.addEventListener('click', this._onClickOutside);
    }

    _onClickOutside = () => {
        this._currentlyOpenMenu && this._currentlyOpenMenu.hide();
        this._currentlyOpenMenu = null;
    };

    /**
     * @param {SubmenuActiveEvent} e
     * @private
     */
    _onSubmenuActive = (e) => {
        this._currentlyOpenMenu = e.menu;
    };

    _onSubmenuInactive = () => {
        this._currentlyOpenMenu = null;
    };

    _transformSubnavs() {
        this.debug.log('Transforming menu');

        const subnavButtons = Selectable.queryAll(
            this._selectors.subNavButtons,
            this._navbar
        );

        // convert buttons to spans
        this._subnavButtons = subnavButtons
            .map((button) => {
                if (
                    !button.classList.contains(
                        this._classNames.subnavMobileButton
                    )
                ) {
                    return new LargescreenSubnavButton(
                        button,
                        this._classNames
                    );
                } else {
                    return null;
                }
            })
            .filter((e) => e !== null);

        // submenus inside of dropdowns
        this._subnavMenus = Selectable.queryAll(
            this._selectors.subNavigations,
            this._navbar
        );
        this._subnavMenus.forEach((subnav) => {
            subnav.removeAttribute('aria-hidden');
        });
    }

    _restoreSubnavs() {
        this.debug.log('Restoring menus');

        this._subnavButtons.forEach((largescreenSubnavButton) =>
            largescreenSubnavButton.restore()
        );

        this._subnavMenus.forEach((subnav) => {
            subnav.setAttribute('aria-hidden', 'true');
        });
    }
}
