import { $, $$ } from '@gebruederheitz/wp-frontend-utils';

/**
 * Unified interface to select DOM elements by ID or CSS selector based on a
 * selector definition.
 * queryAll will always return an _array_ of Elements (or null), not a NodeList.
 */
export class Selectable {
    /**
     * @param {string} selector
     * @param {?"id"|"generic"} type
     * @param {?ElementType} parent
     * @return {?ElementType}
     */
    static query({ selector, type = null }, parent = null) {
        let element;

        switch (type) {
            case 'id':
                element = document.getElementById(selector);
                break;
            case 'generic':
            default:
                element = parent !== null ? $(parent)(selector) : $()(selector);
        }

        return element;
    }

    /**
     *
     * @param {string} selector
     * @param {?"id"|"generic"} type
     * @param {?ElementType} parent
     * @return {array<null|ElementType>}
     */
    static queryAll({ selector, type = null }, parent = null) {
        let elements;

        switch (type) {
            case 'id':
                elements = [document.getElementById(selector)];
                break;
            case 'generic':
            default:
                elements = Array.from(
                    parent !== null ? $$(parent)(selector) : $$()(selector)
                );
        }

        return elements;
    }
}
