import { Debuggable } from '@gebruederheitz/wp-frontend-utils';
import mitt from 'mitt';

import { SmallscreenNavMenu } from './smallscreen-nav-menu';
import { Selectable } from './util/selectable';

/**
 * @typedef {Element|HTMLElement|Node} ElementType
 */
export class SmallscreenNav extends Debuggable {
    /**
     * @type {array<SmallscreenNavMenu|LanguageMenu>}
     * @private
     */
    _openSubMenuStack = [];

    /**
     * @type {Object<string, string>}
     * @private
     */
    _classNames;

    /**
     * @type {ElementType}
     * @private
     */
    _closeButton;

    /**
     * @type {EventProxy}
     * @private
     */
    _eventEmitter;

    /**
     * @type {boolean}
     * @private
     */
    _isNavigationOpen = false;

    /**
     * @type {ElementType}
     * @private
     */
    _mainNav;

    /**
     * @type {ElementType}
     * @private
     */
    _navigationPanel;

    /**
     * @type {ElementType}
     * @private
     */
    _openButton;

    /**
     * @type {Map<ElementType, SmallscreenNavMenu>}
     * @private
     */
    _subMenus = new Map();

    /**
     * @type {SelectorCategory}
     * @private
     */
    _selectors;

    /**
     * @param {EventProxy} eventProxy
     * @param {SelectorList} selectors
     * @param {Object<string, string>} classNames
     * */
    constructor(eventProxy = null, selectors, classNames) {
        super('SmallscreenNav');

        if (!document.getElementById('ghwp-navbar')) {
            return;
        }

        this._eventProxy = eventProxy || mitt();
        this._selectors = selectors.smallscreen;
        this._classNames = classNames;

        this._openButton = Selectable.query(this._selectors.openButton);
        this._closeButton = Selectable.query(this._selectors.closeButton);
        this._mainNav = Selectable.query(this._selectors.mainNav);
        this._navigationPanel = Selectable.query(this._selectors.navPanel);

        this._initSubMenus();
        this._listen();
    }

    destroy() {
        // Remove all event listeners, destroy all child objects
        this._attachOrDetachEvents(false);
        this._subMenus.forEach((subMenu) => {
            subMenu.destroy();
        });
        this._navigationPanel.classList.remove(this._classNames.mobileMenuOpen);
        document.body.classList.remove(
            this._classNames.bodyOverflowOverlayOpen
        );
    }

    _attachOrDetachEvents(attach = true) {
        const domMethod = attach ? 'addEventListener' : 'removeEventListener';

        this._openButton[domMethod]('click', this._onToggleNavigation);
        this._closeButton[domMethod]('click', this._onToggleNavigation);
    }

    _initSubMenus() {
        Selectable.queryAll(this._selectors.subMenuButton).forEach(
            (navToggleButton) => {
                this._subMenus.set(
                    navToggleButton,
                    new SmallscreenNavMenu(navToggleButton, this._eventProxy)
                );
            }
        );
    }

    _listen() {
        this._attachOrDetachEvents();
    }

    _onToggleNavigation = () => {
        this._isNavigationOpen = !this._isNavigationOpen;

        this._closeButton.setAttribute('aria-expanded', this._isNavigationOpen);
        this._openButton.setAttribute('aria-expanded', this._isNavigationOpen);

        const navPanelMethod = this._isNavigationOpen ? 'add' : 'remove';
        const bodyMethod = this._isNavigationOpen ? 'add' : 'remove';

        this._navigationPanel.classList[navPanelMethod](
            this._classNames.mobileMenuOpen
        );

        document.body.classList[bodyMethod](
            this._classNames.bodyOverflowOverlayOpen
        );
    };
}
