export class LanguageMenuToggleEvent {
    static EVENT_NAME = 'language-menu-toggle';

    /**
     * @type {boolean}
     */
    isOpen;

    /**
     * @type  {LanguageMenu}
     */
    languageMenu;

    /**
     * @type {boolean}
     */
    hasBeenUnstacked = false;

    /**
     * @param {boolean} isOpen
     * @param {LanguageMenu} languageMenu
     * @param {boolean} hasBeenUnstacked
     */
    constructor(isOpen, languageMenu, hasBeenUnstacked = false) {
        this.isOpen = isOpen;
        this.languageMenu = languageMenu;
        this.hasBeenUnstacked = hasBeenUnstacked;
    }
}
