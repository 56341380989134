import { Debuggable } from '@gebruederheitz/wp-frontend-utils';
import { SmallscreenSearchActiveEvent } from './events/smallscreen-search-active-event';
import { Selectable } from './util/selectable';

export class SiteSearch extends Debuggable {
    /**
     * @type {ElementType}
     * @private
     */
    _activeButton;

    /**
     * @type {ElementType}
     * @private
     */
    _buttonLargescreen;

    /**
     * @type {ElementType}
     @private
     */
    _buttonSmallscreen;

    /**
     * @type {Object<string, string>}
     * @private
     */
    _classNames;

    /**
     * @type {ElementType}
     * @private
     */
    _closeButton;

    /**
     * @type {EventProxy}
     * @private
     */
    _eventProxy;

    /**
     * @type {ElementType}
     * @private
     */
    _input;

    /**
     * @type {boolean}
     * @private
     */
    _isOpen = false;

    /**
     * @type {ElementType}
     * @private
     */
    _searchbar;

    /**
     * @type {SelectorCategory}
     * @private
     */
    _selectors;

    /**
     * @param {EventProxy} eventProxy
     * @param {SelectorList} selectors
     * @param {Object<string, string>} classNames
     */
    constructor(eventProxy, selectors, classNames) {
        super('SiteSearch');

        this._eventProxy = eventProxy;
        this._selectors = selectors.searchbar;
        this._classNames = classNames;

        this._buttonLargescreen = Selectable.query(
            this._selectors.buttonLargescreen
        );
        this._buttonSmallscreen = Selectable.query(
            this._selectors.buttonSmallscreen
        );

        if (!this._buttonLargescreen) return;

        this._searchbar = Selectable.query(this._selectors.searchbar);
        this._input = Selectable.query(this._selectors.searchInput);
        this._closeButton = Selectable.query(this._selectors.closeButton);

        this._listen();
    }

    _hide() {
        this._isOpen = false;
        this._activeButton.setAttribute('aria-expanded', this._isOpen);
        this._searchbar.setAttribute('aria-hidden', 'true');
        document.body.classList.remove(
            this._classNames.bodyOverflowOverlayOpen
        );
        document.activeElement.blur();
    }

    _listen() {
        this._buttonLargescreen &&
            this._buttonLargescreen.addEventListener(
                'click',
                this._onShowSearch
            );
        this._buttonSmallscreen &&
            this._buttonSmallscreen.addEventListener(
                'click',
                this._onShowSearch
            );
        this._closeButton.addEventListener('click', this._onCloseClick);
    }

    _onCloseClick = () => {
        this._hide();
    };

    _onShowSearch = ({ currentTarget }) => {
        this._activeButton = currentTarget;
        if (currentTarget === this._buttonSmallscreen) {
            this._eventProxy.triggerEvent(SmallscreenSearchActiveEvent);
        }
        this._show();
    };

    _show() {
        this._isOpen = true;
        this._activeButton.setAttribute('aria-expanded', this._isOpen);
        this._searchbar.setAttribute('aria-hidden', 'false');
        document.body.classList.add(this._classNames.bodyOverflowOverlayOpen);
        this._input.focus();
    }
}
