import _debounce from 'lodash-es/debounce';

import {
    classNames as defaultClassNames,
    selectors as defaultSelectors,
} from './config/selectors';
import { eventProxy } from './events/event-proxy';
import { LanguageMenu } from './language-menu';
import { LargescreenNav } from './largescreen-nav';
import { SiteSearch } from './site-search';
import { SmallscreenNav } from './smallscreen-nav';
import { Selectable } from './util/selectable';

const DEFAULT_BREAKPOINT = 1200;

/**
 * @enum {string}
 */
const Breakpoint = {
    SMALL: 'small',
    LARGE: 'large',
};

export class NavMenus {
    /**
     * @param {number} breakpoint  Device width in pixels at which to switch
     *                             between smallscreen and largescreen nav menus
     * @param {SelectorList} selectors
     * @param {Object<string, string>} classNames
     */
    static factory = (
        breakpoint = DEFAULT_BREAKPOINT,
        selectors = defaultSelectors,
        classNames = defaultClassNames
    ) => {
        new this(breakpoint, selectors, classNames);
    };

    /**
     * @type {number}
     * @private
     */
    _breakpoint = DEFAULT_BREAKPOINT;

    /**
     * @type {Object<string, string>}
     * @private
     */
    _classNames;

    _currentNav;

    /**
     * @type {EventProxy}
     * @private
     */
    _eventProxy;

    /**
     * @type {Breakpoint}
     * @private
     */
    _initializedBreakpoint = null;

    /**
     * @type {SelectorList}
     * @private
     */
    _selectors;

    /**
     * @param {number} breakpoint  Device width in pixels at which to switch
     *                             between smallscreen and largescreen nav menus
     * @param {SelectorList} selectors
     * @param {Object<string, string>} classNames
     */
    constructor(
        breakpoint = DEFAULT_BREAKPOINT,
        selectors = defaultSelectors,
        classNames = defaultClassNames
    ) {
        this._eventProxy = eventProxy;
        this._breakpoint = breakpoint;
        this._classNames = classNames;
        this._selectors = selectors;

        this._initLanguageMenus();
        this._initSearchBar();
        this._listen();
        this._onResize();
    }

    _initLanguageMenus() {
        new LanguageMenu(
            Selectable.query(this._selectors.languageMenu.button),
            Selectable.query(this._selectors.languageMenu.menu),
            this._eventProxy
        );
        new LanguageMenu(
            Selectable.query(this._selectors.languageMenu.buttonMobile),
            Selectable.query(this._selectors.languageMenu.menuMobile),
            this._eventProxy
        );
    }

    _initLargeScreenNav() {
        return new LargescreenNav(
            this._eventProxy,
            this._selectors,
            this._classNames
        );
    }

    _initSearchBar() {
        new SiteSearch(this._eventProxy, this._selectors, this._classNames);
    }

    _initSmallscreenNav() {
        return new SmallscreenNav(
            this._eventProxy,
            this._selectors,
            this._classNames
        );
    }

    _getCurrentBreakpoint() {
        return window.matchMedia(`(min-width: ${this._breakpoint}px)`).matches
            ? Breakpoint.LARGE
            : Breakpoint.SMALL;
    }

    _listen() {
        window.addEventListener('resize', _debounce(this._onResize, 500));
    }

    _onResize = () => {
        const newBreakpoint = this._getCurrentBreakpoint();
        if (this._initializedBreakpoint !== newBreakpoint) {
            this._initializedBreakpoint = newBreakpoint;
            this._currentNav && this._currentNav.destroy();

            if (newBreakpoint === Breakpoint.SMALL) {
                this._currentNav = this._initSmallscreenNav();
            } else {
                this._currentNav = this._initLargeScreenNav();
            }
        }
    };
}
