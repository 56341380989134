import whenDomReady from 'when-dom-ready';
// Polyfills
import objectFitImages from 'object-fit-images';
// Modules
import { modal } from './frontend/lightbox';
import { eventCard } from './frontend/eventCard';
import { pardotIframeResizer } from './frontend/pardot-responsive-iframes';
import { NavMenus } from './frontend/nav-menu';
import { selectors } from './frontend/utils/selectors';
import {
    safeCallback,
    safeModuleInit,
    whenMatchFound,
} from './frontend/utils/isolated-modules';
import { Debuggable } from '@gebruederheitz/wp-frontend-utils';
import { ResizeListener } from '@gebruederheitz/wp-block-slideshow/dist/frontend';

export function initFrontendScripts() {
    whenDomReady().then(async () => {
        // used later by consent management
        let lightboxFactory = null;
        let resizeListener = null;
        // Set to true to get debug output when developing – do not commit that
        // change to production!
        Debuggable.setGlobalDebug(false);

        safeModuleInit('Polyfill: object-fit', objectFitImages);
        safeModuleInit('Navigation menu modules', NavMenus.factory);

        whenMatchFound('Styled selects', selectors.styledSelect, async () => {
            const { styledSelect } = await import('./frontend/styledSelect');
            styledSelect(selectors.styledSelect);
        });

        safeModuleInit('Content modals', modal); // Modals are included on every page for the contact form

        whenMatchFound(
            'Lightboxes (images, videos)',
            [selectors.lightbox, selectors.videoLightbox],
            async () => {
                const { factory } = await import('./frontend/lightbox');
                factory.images();

                // This selector will only target elements that _don't_ have a
                // data-ghct-type attribute, so it will (a) use anything that's
                // set to "internal" and (b) be used when no consent management
                // is used at all.
                document
                    .querySelectorAll(selectors.videoLightbox)
                    .forEach((e) => {
                        factory.createFromElement(e);
                    });

                // expose for use by consent management
                lightboxFactory = factory;
            }
        );

        safeModuleInit('Event card', eventCard);

        whenMatchFound(
            'Stats counter & stats ticker',
            [selectors.statsCounter, selectors.ticker],
            async () => {
                const { statsCounters, tickers } = await import(
                    './frontend/counters'
                );
                statsCounters(selectors.statsCounter);
                tickers(selectors.ticker);
            }
        );

        whenMatchFound('Tabs', selectors.tabs, async () => {
            const { tabs } = await import('./frontend/tabs');
            tabs({ selector: selectors.tabs });
        });

        whenMatchFound('Scrollable Tables', selectors.table, async () => {
            resizeListener = new ResizeListener();
            const { tableScroll } = await import('./frontend/table-scroll');
            tableScroll(resizeListener, selectors.table);
        });

        whenMatchFound('Read more block', selectors.readMore, async () => {
            const { ReadMore } = await import('./frontend/read-more');
            ReadMore.Factory(selectors.readMore);
        });

        whenMatchFound(
            'Slideshows & carousels',
            selectors.slideshow,
            async () => {
                const { slideshows } = await import(
                    './frontend/slideshow/ghwp-slideshows'
                );
                slideshows(selectors.slideshow, resizeListener);
            }
        );

        whenMatchFound('Post filter', selectors.postFilter, async () => {
            const { postFilter } = await import('./frontend/postFilter');
            postFilter();
        });

        safeModuleInit(
            'Responsive form iframes from Pardot',
            pardotIframeResizer
        );

        whenMatchFound(
            'Syntax highlighting for pre-blocks with prismJs',
            selectors.prism,
            async () => {
                const { prismSyntaxHighlighting } = await import(
                    './frontend/prismjs'
                );
                prismSyntaxHighlighting();
            }
        );

        whenMatchFound(
            'Animated progress bar',
            selectors.progressbar,
            async () => {
                const { Progressbar } = await import('./frontend/progressbar');
                new Progressbar(selectors.progressbar);
            }
        );

        whenMatchFound(
            'Efficiency calculator gutenberg block (calculation & animations)',
            selectors.efficiencyCalculator,
            async () => {
                const { EfficiencyCalculatorFactory } = await import(
                    './frontend/efficiency-calculator'
                );
                new EfficiencyCalculatorFactory(selectors.efficiencyCalculator);
            }
        );

        whenMatchFound(
            'Inputs with states signalling whether they have been interacted with',
            selectors.inputInteraction.join(', '),
            async () => {
                const { InputInteractionFactory } = await import(
                    './frontend/input-interaction'
                );
                new InputInteractionFactory(selectors.inputInteraction);
            }
        );

        if (window.ghwp?.cmType === 'onetrust') {
            safeCallback(
                'External embeds / third-party services & consent management',
                async () => {
                    const { initConsentManagement } = await import(
                        './frontend/consent-management'
                    );
                    initConsentManagement(lightboxFactory).then();
                }
            );
        } else if (window.ghwp?.cmType === 'fusedeck') {
            safeCallback('Consent management settings footer button', () => {
                document
                    .querySelectorAll('[href="#consent-settings"]')
                    .forEach((b) => {
                        b.addEventListener('click', (e) => {
                            e.preventDefault();
                            window.fdConsentManager &&
                                window.fdConsentManager.showBanner();
                        });
                    });
            });
        }

        whenMatchFound(
            'Auto-resizing for iframes by personio.de',
            selectors.personioIframe,
            async () => {
                const { personioIframeResize } = await import(
                    './frontend/personio-resize'
                );
                personioIframeResize(selectors.personioIframe);
            }
        );

        whenMatchFound(
            'Collapsibles / accordions',
            selectors.collapsible,
            async () => {
                const { Collapsible } = await import('./frontend/collapsible');
                Collapsible.factory({});
            }
        );
    });
}
