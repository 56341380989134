/**
 * @typedef {{selector: string, type?: "id"|"generic"}} SelectorDefinition
 * @typedef {Object<string, SelectorDefinition>} SelectorCategory
 * @typedef {Object<string, SelectorCategory>} SelectorList
 * @type {SelectorList} selectors
 */
export const selectors = {
    largescreen: {
        navbar: {
            selector: 'ghwp-main-nav',
            type: 'id',
        },
        navButtons: {
            selector: '#ghwp-main-nav > li > .ghwp-main-nav__toggle',
        },
        subNavButtons: {
            selector: '.ghwp-main-nav__subnav > .ghwp-main-nav__toggle',
        },
        subNavigations: {
            selector: '.ghwp-main-nav__subnav > .ghwp-main-nav__sub',
        },
    },
    smallscreen: {
        openButton: {
            selector: 'ghwp-main-open',
            type: 'id',
        },
        closeButton: {
            selector: 'ghwp-main-close',
            type: 'id',
        },
        mainNav: {
            selector: 'ghwp-main-nav',
            type: 'id',
        },
        navPanel: {
            selector: '.ghwp-header__menu',
        },
        subMenuButton: {
            selector: '.ghwp-main-nav__toggle',
        },
    },
    searchbar: {
        buttonLargescreen: {
            selector: 'ghwp-toggle-search',
            type: 'id',
        },
        buttonSmallscreen: {
            selector: 'ghwp-toggle-search-mobile',
            type: 'id',
        },
        closeButton: {
            selector: 'ghwp-close-search',
            type: 'id',
        },
        searchbar: {
            selector: 'ghwp-header-search',
            type: 'id',
        },
        searchInput: {
            selector: 'ghwp-searchterm',
            type: 'id',
        },
    },
    languageMenu: {
        button: {
            selector: 'ghwp-toggle-lang',
            type: 'id',
        },
        menu: {
            selector: 'ghwp-select-lang',
            type: 'id',
        },
        buttonMobile: {
            selector: 'ghwp-toggle-lang-mobile',
            type: 'id',
        },
        menuMobile: {
            selector: 'ghwp-select-lang-mobile',
            type: 'id',
        },
    },
};

export const classNames = {
    mainNavActive: 'nav-active',
    mainNavActiveParent: 'main-nav-active',
    subnavButton: 'ghwp-main-nav__toggle',
    subnavButtonText: 'ghwp-main-nav__subheading',
    subnavMobileButton: 'ghwp-main-nav__toggle--mobile',
    mobileMenuOpen: 'ghwp-header__menu--open',
    bodyOverflowOverlayOpen: 'ghwp-overlay--open',
};
